#post-1373 h1.article-h1{
	font-size: 1.5rem;
}

@media print{
	.slide-image{
		-webkit-print-color-adjust: exact !important;
		height: 25vw;
	}
}
