$theme-blue: #004785;
$theme-green: #66cc99;

//sizes
$tablet: 641px;
$desktop: 1025px;

@mixin no-select(){
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

@mixin bar-before($padding: 0.25em){
	&:before{
		@include no-select;
		display: inline-block;
		content: '|';
		padding: 0 $padding;
	}
}
