[data-template="events"] #content .wrapper{
	@media screen and (min-width: $tablet){
		box-sizing: border-box;
		padding-left: 4rem;
	}
	@media screen and (min-width: $desktop){
		padding-left: 8rem;
	}
}

.calendar-container{
	width: 100%;
	max-width: 180px;
	height: auto;
	position: relative;
	display: block;
	margin: 2.5rem auto;
	@media screen and (min-width: $tablet){
		max-width: 240px;
		margin-top: 0;
	}

	.calendar{
		 .ui-datepicker{
			width: 100%;
			box-sizing: border-box;
		}
		.ui-widget-header{
			border-color: $theme-blue;
			text-align: center;
			font-weight: bold;
			position: relative;
			margin-bottom: 1em;

			.ui-datepicker-prev{
				float: left;
				margin-left: 0.5em;
				cursor: pointer;
				@media screen and (min-width: $tablet){
					margin-left: 1em;
				}
			}
			.ui-datepicker-next{
				float: right;
				margin-right: .5em;
				cursor: pointer;
			}
		}
		td:not(.has-events){
			-webkit-pointer-events: none;
			-moz-pointer-events: none;
			-ms-pointer-events: none;
			pointer-events: none;

			> a {
				color: #a9a9a9;
			}
		}
		td.has-events > a{
			border-bottom: 2px solid $theme-blue;
		}
		th{
			text-align: right;
		}
		td{
			text-align: right;
			padding: .25em 0;
		}
		td > a {
			color: $theme-blue;
		}
		table{
			padding-right: 0.25em;
			width: 100%;
			height: auto;
		}
	}


	@media screen and (min-width: $tablet){
		margin: 0;

	}
	@media screen and(min-width: $desktop){
		float: right;
		width: 27%;
		height: auto;
		margin: 1rem -3rem 0 0;
	}
	@media print{
		margin: 0;
	}


}
section.events{
	> h1 {
		font-size: 1.5rem;
	}
	.pagination{
		max-width: 640px;
		div{
			display: inline-block;
			&:not(:last-child):not(:first-child){
				padding: 0 1rem;
				font-weight: bold;
			}
		}
		&:last-child{
			margin-top: 2rem;
		}
	}
	@media screen and(min-width: $desktop){
		float: left;
		width: 75%;
		height: auto;
	}
}

article.event{
	box-sizing: border-box;
	.event-content{
		width: 100%;
		height: auto;
		clear: both;
		overflow: hidden;
	}

	img{
		max-width: 100%;
		@media screen and(min-width: $tablet){
			width: 50%;
			max-width: 400px;
			float: left;
		}
		@media print{
			max-width: 75%;
		}
	}

	.event-text{

		@media screen and(min-width: $tablet){

			h1{
				margin-top: 0;
			}

			width: 50%;
			max-width: 800px;
			float: left;
			padding-left: 1rem;
			box-sizing: border-box;
		}
	}

}
