#post-1365 h1.article-h1{ 
	font-size: 1.5rem;
}

#post-1365 strong {
	font-size: 1rem;
}

#post-1365 b {
	font-size: 1rem;
}

#post-1365 img{
	width: auto;
	height: auto;
}