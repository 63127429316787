.wrapper{
	max-width: 640px;

	@media screen and (min-width: $tablet){
		box-sizing: border-box;
		padding-left: 4rem;
	}
	@media screen and (min-width: $desktop){
		padding-left: 8rem;
	}
	h2{
		font-size: 1.5rem;
	}

	h3{
		font-size: 1rem;
		margin-top: 2em;
	}
}

article{
	margin-top: 2rem;
	@media screen and (min-width: $tablet){
		margin-top: 4rem;
	}
	@media screen and (min-width: $desktop){
		margin-top:6rem;
	}

	&:first-child{
		margin-top: 2rem;

		@media screen and (min-width: $tablet){
			margin-top: 0rem;
		}

	}
}
