[data-template="blog"] #content .wrapper{
	@media screen and (min-width: $tablet){
		box-sizing: border-box;
		padding-left: 4rem;
	}
	@media screen and (min-width: $desktop){
		padding-left: 8rem;
	}
}

article.post{
	max-width: 640px;
	width: 100%;

	.image-container > img, .content img{
		max-width: 100%;
	}

	.content img{
		margin: 1rem 0;
	}
	
	padding-bottom: 1rem;
	border-bottom: 1px solid $theme-blue;
	margin-bottom: 2rem;
}