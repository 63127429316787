[data-template="artists"] #content .wrapper{
	@media screen and (min-width: $tablet){
		box-sizing: border-box;
		padding-left: 4rem;
	}
	@media screen and (min-width: $desktop){
		padding-left: 8rem;
	}
	@media print{
		padding-left: 8rem;
	}
}

.column{
	display: block;
	width: auto;
	height: auto;
	margin: 0 0 0 0;
	padding: 0;
	list-style: none;
	box-sizing: border-box;
	&:first-of-type li:first-child{
		border-top: 1px solid $theme-blue;
	}

	@media screen and (min-width: $tablet){
		width: 147px;
		float: left;
		margin-right: 0.5rem;
		margin-top: 1.3rem;
		li:first-child{
			border-top: 1px solid $theme-blue;
		}

		&:nth-of-type(3) li:first-child{
			border-top: none;
		}

		& + .column{
			margin-top: 2.75rem;
		}
	}

	@media screen and (min-width: 900px){
		margin-right: 1.5rem;
		&:nth-of-type(3) li:first-child{
			border-top: 1px solid black;
		}
	}

	@media print{
		width: 147px;
		float: left;
		margin-right: 1.5rem;
		margin-top: 1.3rem;
		li:first-child{
			border-top: 1px solid $theme-blue;
		}

		&:nth-of-type(3) li:first-child{
			border-top: none;
		}

		& + .column{
			margin-top: 2.75rem;
		}
	}

}

.column li{
	display: block;
	width: 147px;
	height: auto;
	text-align: left;
	border-bottom: 1px solid black;

	> a {
		padding: 0.75rem 0.5rem;
		width: auto;
		@media screen and (min-width: $tablet){
			padding: 0.75rem 0 0.75rem 0.5rem;
		}
		display: inline-block;
		text-transform: lowercase;
		&:hover{
			color: black;
		}
	}
}
