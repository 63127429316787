section.gallery{
		width: 100%;
		height: auto;
		display: block;
		position: relative;
		margin: 0 0 2rem;
	}

	ul.slides{
		display: block;
		list-style: none;
		width: 100%;
		position: relative;
		height: auto;
		overflow-x: hidden;
		margin: 0;
		padding: 0;
	}

	li.slide{
		position: absolute;
		left: 100%;
		top: 0;
		width: 100%;
		height: auto;
		display: block;

		&.active{
			position: relative;
			left: 0;
		}
	}

	.slide-controls{
		@include no-select;
		a{
			@include no-select;
			font-size: 1.5rem;
			display: inline-block;
	         padding-top: 0.75rem;

	          @media screen and (min-width: $tablet){
	            font-size: 1.15rem;
	          }
		}
		a.slide-prev, a.slide-zoom{
			@include bar-before(0.5em);
		}

		a.slide-next{
			padding-left: 0.5em;
			padding-top: 0.5em;
		}
	}

	.slide-image{
		width: 100%;
		height: 80vw;
		position: relative;
		background-size: contain;
		background-position: 0% 50%;
		background-repeat: no-repeat;
		background-color: rgba(0,0,0,0);

		@media screen and (min-width: $tablet){
			height: 350px;

		}

		@media screen and (min-width: 750px){
			height: 400px;

		}

		@media screen and (min-width: 850px){
			height: 500px;
		}

		@media screen and (min-width: $desktop){
			height: 625px;
		}

		@media screen and (min-width: 1200px){
			height: 560px;
		}

		@media screen and (min-width: 1300px){
			height: 640px;
		}
	}

	.slide-modal{
		position: fixed;
		width: 0;
		height: 0;
		top: 0;
		left: 0;
		z-index: 1000;
		overflow: hidden;
		background-color: white;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		&.open{
			background-origin: content-box;
			box-sizing: border-box;
			padding: 2.5%;
			width: 100%;
			height: 100%;
			@media screen and (max-width: 400px){
				padding:15%;
			}
			@media print{
				-webkit-print-color-adjust: exact !important;
				height: 60% !important;
				margin-top: 15rem !important;
			}
		}
	}

	.modal-cover{
		@media print{
			background-color: white !important;
			height: 100% !important;
			width: 100% !important;
			position: fixed !important;
			top: 0 !important;
			z-index: 999 !important;
		}
	}

	.slide-content{
		max-height: 0;
		transition: max-height .25s;
		overflow: hidden;
		.open &{
			max-height: 300px;
		}
	}
