[data-template="event"] #content .wrapper{
	@media screen and (min-width: $tablet){
		box-sizing: border-box;
		padding-left: 4rem;
	}
	@media screen and (min-width: $desktop){
		padding-left: 8rem;
	}
}

.event-header{
	> h2{
		font-size: 1rem;
	}
}

section.header h1{
	font-size: 1.5rem;
}