body > .left{
	width: 100%;
	position: relative;
	box-sizing: border-box;
	padding: .5rem;
	margin-bottom: 1rem;
	height: 5rem;
	overflow-y: visible;

	@media screen and (min-width: $tablet){
		width: 160px;
		position: absolute;
		left: 0.5rem;
		top: 15%;
		height: auto;
		margin-bottom: 0;
		padding: 0.5rem;
		z-index: 1;
		box-sizing: border-box;
	}
}

.logo{
	display: inline-block;
	max-height: 100%;
	width: auto;
	max-width: 50%;
	position: relative;
	z-index: 9999;
	@media print{
		img{
			height: 8.65rem !important;
			top: 6.5rem !important;
			padding-left: 1rem;
		}
	}
	img{
		position: relative;
		top: -0.5rem;
		height: 6.65rem;
		width: auto;
	}

	@media screen and (min-width: $tablet){
		display: block;
		width: 100%;
		max-width: none;
		height: auto;
		float: none;
		box-sizing: border-box;
		padding-left: 1.25rem;
		img{
			width: auto;
			max-height: none;
			height: auto;
		}
	}

}

nav{
	display: block;
	position: absolute;
	float: right;
	box-sizing: border-box;
	padding-top: 4rem;
	height: 3rem;
	-webkit-transition: height 0.5s;
	-moz-transition: height 0.5s;
	-ms-transition: height 0.5s;
	transition: height 0.5s;
	width: 100%;
	overflow-y: hidden;
	overflow-x: visible;
	z-index: 100;
	top: 0;
	right: 0;
	&.active{
		height: 150vh;
	}

	@media screen and (min-width: $tablet){
		float: none;
		padding-top: 1rem;
		right: 0;

		&:before{
			content: none;
		}
	}

	@media print{
		overflow-y: visible;
		top: 10rem;
		left: 0.5rem;
	}
}

.nav-inner{
	position: absolute;
	margin-bottom: 5rem;
	left: 0;
	top: 4.5rem;
	height: auto;
	width: 147px;
	z-index: 3;
	box-sizing: border-box;
}

.nav-links{
	margin: 2rem 0 0 0;
	@media screen and (min-width: $tablet){
		margin-top: 0;
	}
	box-sizing: border-box;
	display: block;
	width: 100%;
	height: auto;
	position: relative;
	list-style: none;
	background-color: white;
	padding: 1rem 0.5rem 0;

	> a > li{
		display: block;
		box-sizing: border-box;
		width: 100%;
		height: auto;
		padding: 1.25rem 0.5rem;
		border-bottom: 1px solid black;
	}

	> a:first-of-type > li{
		border-top: 1px solid black;
		@media screen and (min-width: $tablet){
			border-top: none;
		}
	}

	a{
		text-decoration: none;
		&:hover{
			color: black;
		}
	}

	@media screen and (min-width: $tablet){
		border-top: 1px solid black;
		text-align: left;
		padding: 0;
		margin-top: 1rem;
		> a > li{
			padding: 0.75rem 0.5rem;
		}
		> li{
			padding: 0.75rem 0 0.75rem 0.5rem;
		}

	}

	@media print{
		> a > li{
			padding: 0.75rem 0.5rem;
		}
		> li{
			padding: 0.75rem 0 0.75rem 0.5rem;
		}
	}
}

@media screen and (min-width: $tablet){

	nav, .nav-inner{
		position: relative;
		width: 100%;
		top: 0;
		height: auto;
	}

}
