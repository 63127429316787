[data-template="artist"] #content .wrapper{
  @media screen and (min-width: $tablet){
    box-sizing: border-box;
    padding-left: 4rem;
  }
  @media screen and (min-width: $desktop){
    padding-left: 8rem;
  }
}

.artist-header{
	h1{
		text-transform: lowercase;
		letter-spacing: 0.1em;
	}
  	.artist-nav{
  		display: block;
  		width: 100%;
  		height: auto;
  		position: relative;

      h3{
      }

  		> ul{
  			list-style: none;
  			display: block;
  			padding: 0;
  			text-align: left;

  			> li{
          cursor: pointer;
  				display: inline-block;
  				color: $theme-blue;
  				padding: 0.5rem 0 0.25rem 0;
  				&:not(:last-of-type):after{
  					display: inline-block;
  					content: '|';
  					padding: 0 0.5rem;
  					color: $theme-blue;
  				}
          @media screen and (min-width: $tablet){
            padding-top: 0;
          }
  			}
  		}
  	}

  	.modals{
  		position: relative;
  		width: 100%;
  		height: 0;
      height: auto;
  	}

  	.modals > div{
  		position: absolute;
  		left: 0;
  		top: 0;
  		background-color: white;
  		width: 100%;
  		max-height: 0;
  		height: auto;
  		z-index: 4;
  		overflow-y: hidden;

  		> .modal-inner{
        h3{
          margin-top: 0;
        }
  			padding: 1rem 1rem;
          @media screen and (min-width: $tablet){
            padding-top: 1rem;
          }
  		}
  	}

  	.modals > div.active{
  		max-height: none;
      position: relative;
  	}
}

@media screen and (min-width: $tablet){
	body[data-template="artist"] #content{
		padding-left: 200px;
	}
}
