html{
	margin-top: 0 !important;
	font-size: 13px;
}

body{
	margin: 0;
	padding: 0;
	position: relative;
	width: 100vw;
	overflow-x: hidden;
	min-height: 100vh;
	font-family: Arial, sans-serif;

	img{
		max-width: 100%;
	}
}

#content{
	position: relative;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	padding: 1rem 1rem 2rem;
	max-width: 1400px;
}

a{
	color: $theme-blue;
	text-decoration: none;

	&:hover, &.active{
		color: black;
	}
}

.header-image{
	display: block;
	position: relative;
	width: auto;
	max-width: 100%;
	max-height: 500px;
}

@media screen and (min-width: $tablet){
	#content{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: auto;
		max-width: 1400px;
		box-sizing: border-box;
		padding-left: 180px;
		padding-right: 2rem;
		margin-top: 15vh;
		z-index: 0;
	}
}

@media print {
	#content{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: auto;
		max-width: 1400px;
		box-sizing: border-box;
		padding-left: 180px;
		padding-right: 2rem;
		margin-top: 15vh;
		z-index: 0;
	}
}
